.form {
  margin: 1rem 0;
  height: 19rem;
  overflow: scroll;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  font-weight: bold;
  margin-bottom: 0.25rem;
  display: block;
}

.control input {
  font: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 20rem;
  max-width: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.actions button {
  font: inherit;
  color: #5a1a01;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 25px;
  padding: 0.5rem 2rem; 
}

.actions button:hover,
.actions button:active {
  background-color: #ffe6dc;
}

.actions .submit {
  border: 1px solid #5a1a01;
  background-color: #5a1a01;
  color: white;
}

.actions .submit:hover,
.actions .submit:active {
  background-color: #7a2706;
}

.invalid label {
  color: #ca3e51;
}

.invalid input {
  border-color: #aa0b20;
  background-color: #ffeff1;
}